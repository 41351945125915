import React from "react";
import { _id } from "./../Assets/js/utils/functions";
import { get_request, post_request } from "./../Assets/js/utils/services";
import { emitter } from "./../Giit";
import Daily_outline from "./daily_outline";
import Loadindicator from "./loadindicator";
import Course_outline from "./course_outline";
import Curriculum_form from "../Sections/curriculum_form";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/autoplay";
import { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import Text_btn from "./text_btn";

let sorted_dow = new Array(
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday"
);

class Update_curriculum extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      weeks: new Array(),
      show_outlines: true,
      dow: new Array(),
      outlines: new Object(),
    };
  }

  componentDidMount = async () => {
    let { course } = this.props;

    let weeks = await get_request(`curriculum/${course._id}`),
      active_week,
      dow = new Set();

    weeks.map((w) => {
      w?.dow?.map((d) => dow.add(d));
    });

    this.setState({ weeks, dow: Array.from(dow) });

    this.daily_outline = () => {};

    emitter.listen("daily_outline", this.daily_outline);
  };

  submit = async () => {
    this.setState({ loading: true });
    let { course } = this.props;

    this.setState({});
  };

  active_week = (week) => {
    this.setState({ active_week: week });
  };

  handle_dow = (day) => {
    let { dow } = this.state;
    if (dow.includes(day)) dow = dow.filter((d) => d !== day);
    else dow.push(day);

    this.setState({ dow });
  };

  add_week = async () => {
    let { course } = this.props;
    let { weeks, dow } = this.state;

    let week = {
      _id: _id("week"),
      days: dow.map((d) => new Object({ day: d })),
      course: course._id,
      dow,
    };
    weeks = [...weeks, week];

    this.setState({ weeks, active_week: week });

    await post_request("add_week", week);
  };

  delete_week = async (week_id) => {
    let { course } = this.props;
    let { weeks, active_week } = this.state;

    weeks = weeks.filter((w) => w._id !== week_id);

    this.setState({
      weeks,
      active_week: active_week?._id === week_id ? null : active_week,
    });

    await post_request(`delete_week`, { week: week_id, course: course._id });
  };

  render() {
    let { course } = this.props;
    let { weeks, active_week, dow } = this.state;

    return (
      <div>
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <br />
            <div className="modal-body">
              <p>Lecture Days of the Week</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {sorted_dow.map((day) => {
                  let selected = dow.includes(day);
                  return (
                    <span
                      key={day}
                      onClick={() => this.handle_dow(day)}
                      style={{
                        borderColor: selected ? "#000132" : "#333",
                        borderWidth: selected ? 1 : 0,
                        borderStyle: "solid",
                        borderRadius: 10,
                        marginRight: 15,
                        marginBottom: 5,
                        cursor: "pointer",
                        padding: 10,
                      }}
                    >
                      <a
                        href="#"
                        style={{
                          padding: 5,
                          textDecoration: "none",
                          color: selected ? "#000132" : "#000",
                          borderRadius: 10,
                          borderWidth: 1,
                          textTransform: "capitalize",
                        }}
                        key={day}
                      >{`${day}`}</a>
                    </span>
                  );
                })}
              </div>

              <p className="mt-3">Weeks</p>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                }}
              >
                {weeks.map((week, index) => (
                  <span key={index}>
                    <Text_btn
                      text={`Week ${index + 1}`}
                      color={week._id === active_week?._id ? null : "#333"}
                      action={() => this.active_week(week)}
                    />

                    <a
                      onClick={() =>
                        window.confirm(`Delete week ${index + 1}?`) &&
                        this.delete_week(week._id)
                      }
                      className="btn btn-action"
                    >
                      <i className={`fas fa-window-close`}></i>
                    </a>
                  </span>
                ))}

                {weeks.length < (course.duration || 12) ? (
                  <a href="#" onClick={this.add_week}>
                    <span>
                      <i class="fa fa-plus"></i>
                      {`New week`}
                    </span>
                  </a>
                ) : null}
              </div>

              <br />

              {active_week && dow?.length ? (
                <Swiper
                  modules={[Autoplay, Pagination]}
                  pagination={{ clickable: true }}
                  slidesPerView={1}
                  loop
                >
                  {dow?.map((d) => (
                    <SwiperSlide key={d}>
                      <Curriculum_form title={d} />
                    </SwiperSlide>
                  ))}
                </Swiper>
              ) : null}

              {/* {active_week && show_outlines
                ? active_week.lectures.map((lecture, index) => (
                    <Daily_outline
                      outline={lecture}
                      key={lecture._id || lecture.topic}
                      index={index}
                      course={course._id}
                      date={outlines[lecture._id || lecture.topic]}
                      in_update={date}
                      in_week={weekindex}
                      enrolled
                    />
                  ))
                : null} */}

              <div style={{ display: "none" }}>
                <Course_outline course={course} enrolled />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Update_curriculum;
export { sorted_dow };
