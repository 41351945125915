import React from "react";

class Add_new_student extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return <div></div>;
  }
}

export default Add_new_student;
